import Swiper from "swiper";
import { Navigation } from "swiper/modules";

const opinionsSection = document.querySelector(".opinions-section");

if (opinionsSection) {
  const opinionsSlider = new Swiper(".opinions-section__items", {
    modules: [Navigation],
    loop: true,
    slidesPerView: 1,
    spaceBetween: 24,

    navigation: {
      nextEl: ".opinions-next-btn",
      prevEl: ".opinions-prev-btn",
    },
    breakpoints: {
      600: {
        slidesPerView: 2,
      },
      900: {
        slidesPerView: 3,
      },
    },
  });
}
