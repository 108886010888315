const helpItems = document.querySelectorAll(".help-section__list__item");
const images = document.querySelectorAll(".help-section__image img");

if (helpItems && images) {
  helpItems.forEach((item, index) => {
    item.addEventListener("click", () => {
      helpItems.forEach((i) => i.classList.remove("active"));
      images.forEach((i) => i.classList.remove("active"));

      helpItems[index]?.classList.add("active");
      images[index]?.classList.add("active");
    });
  });
}
